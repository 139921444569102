/**
 * coupon的期間類型
 */

const items = [
	{
		id: 1,
		name: '月',
	},
	{
		id: 2,
		name: '季',
	},
	{
		id: 3,
		name: '半年',
	},
	{
		id: 4,
		name: '年',
	},
];

export default items;
