/**
 * 文章狀態
 */

const items = [
	{
		id: 1,
		name: '草稿文章',
	},
	{
		id: 2,
		name: '已排程文章',
	},
	{
		id: 3,
		name: '已發佈文章',
	},
];

export default items;
