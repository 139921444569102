/**
 * 列表頁數選項
 */

const items = [
	{
		id: 1,
		name: '每頁10筆',
		count: 10,
	},
	{
		id: 2,
		name: '每頁20筆',
		count: 20,
	},
	{
		id: 3,
		name: '每頁50筆',
		count: 50,
	},
	{
		id: 4,
		name: '每頁100筆',
		count: 100,
	},
];

export default items;
