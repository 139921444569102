/**
 * pinia for 文章留言的api
 */


import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('articleComment', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'asc',
				name: '',
			};

			condition = { ...baseCondition, ...condition };
			try {
				const response = await post('/article_comment/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async delete(q) {
			try {
				const response = await post('/article_comment/delete', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to delete', error);
			}
		},

		async update(id, article_id, content, parent_id, mention_user_ids) {
			try {
				const data = {
					id,
					content,
					article_id,
					parent_id,
					mention_user_ids,
				};
				const response = await post('/article_comment/update', data);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
