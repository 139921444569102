/**
 * 發票類型
 */

const items = [
	{
		id: 1,
		name: '電子發票',
	},
	{
		id: 2,
		name: '手機條碼',
	},
	{
		id: 3,
		name: '自然人憑證',
	},
];

export default items;
