/**
 * pinia for 使用者的api
 */


import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('user', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'priority',
				_orderDirection: 'desc',
				name: '',
				is_in_explore: false,
			};

			condition = { ...baseCondition, ...condition };
			try {
				const response = await post('/user/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async getItem(q) {
			try {
				const response = await post('/user/get_item', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async getOption(ids) {
			try {
				const response = await post('/user/get_option', {
					ids: ids,
				});

				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},
	},
});
