/**
 * 常數集合
 */

import area from './area';
import articleStatus from './articleStatus';
import city from './city';
import couponGenerateType from './couponGenerateType';
import couponPeriodType from './couponPeriodType';
import couponType from './couponType';
import couponValidType from './couponValidType';
import gender from './gender';
import is from './is';
import pageSize from './pageSize';
import productPurchaseType from './productPurchaseType';
import productServiceType from './productServiceType';
import productType from './productType';
import receiptType from './receiptType';

const constants = {
	is,
	gender,
	pageSize,
	productType,
	productPurchaseType,
	productServiceType,
	receiptType,
	area,
	city,
	couponGenerateType,
	couponPeriodType,
	couponType,
	couponValidType,
	articleStatus,
};

export default constants;
