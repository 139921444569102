/**
 * pinia for 文章按讚的api
 */


import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('articleLike', {
	state: () => ({}),
	actions: {
		async update(q) {
			try {
				const response = await post('/article_like/update', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
