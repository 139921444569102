/**
 * coupon的類型
 */

const items = [
	{
		id: 1,
		name: '試閱天數',
	},
	{
		id: 2,
		name: '折扣比例%',
	},
	{
		id: 3,
		name: '折扣固定金額',
	},
];

export default items;
