/**
 * pinia for email登入的api
 */


import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';
import { useStore } from './main.js';

export default defineStore('auth', {
	state: () => ({}),
	actions: {
		async loginByEmail(email, password) {
			try {
				localStorage.removeItem('isAdmin');
				const response = await post('/login/email', { email, password });

				if (response.data.status_id == 0) {
					localStorage.setItem('token', response.data.token);

					const store = useStore();
					if (Object.prototype.hasOwnProperty.call(response.data.user, 'is_admin')) 
								localStorage.setItem('isAdmin', response.data.user.is_admin);
					

					store.setUser({
						id: response.data.user.id,
						name: response.data.user.name,
						email: response.data.user.email,
						photo: response.data.user.photo,
					});
				}

				return response.data;
			} catch (error) {
				console.error('Failed to login', error);
			}
		},
	},
});
