/**
 * pinia for 文章的api
 */

import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('article', {
	state: () => ({}),
	actions: {
		async getIDs(condition = {}) {
			let baseCondition = {};

			condition = { ...baseCondition, ...condition };

			try {
				const response = await post('/article/get_id', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'desc',
				name: '',
				tag: null,
				type_id: null,
				tag_id: null,
				topic_id: null,
			};

			condition = { ...baseCondition, ...condition };

			try {
				const response = await post('/article/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async getItem(q) {
			try {
				const response = await post('/article/get_item', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async update(q) {
			try {
				const response = await post('/article/update', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},

		async delete() {
			try {
				const response = await post('/article/delete');
				return response.data;
			} catch (error) {
				console.error('Failed to delete', error);
			}
		},
	},
});
