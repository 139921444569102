<!--
描述: 
    這個組件負責顯示主要內容，包括:
    - 用戶資訊
    - 操作按鈕
    - 列表數據
    此外，它還與後端 API 整合，支持即時更新功能。
-->
<template>
	<main>
		<component :is="getLayout()" v-if="isReady">
			<router-view v-slot="{ Component }">
				<!-- <keep-alive> -->
				<component :is="Component" />
				<!-- </keep-alive> -->
			</router-view>
		</component>
	</main>
</template>

<script>
import { markRaw, ref } from 'vue';

import { useStore } from './store/main';
import LayoutBlank from './views/__layout/blank.vue';
import LayoutDefault from './views/__layout/default.vue';

const layoutDefault = ref();
layoutDefault.value = markRaw(LayoutDefault);

const layoutBlank = ref();
layoutBlank.value = markRaw(LayoutBlank);


export default {
	data() {
		return {
			isReady: false,
		};
	},

	async mounted() {
		this.setMeta();

		const self = this;
		setTimeout(function () {
			self.isReady = true;
		}, 50);
	},

	setup() {

		const store = useStore();

		return {
			store,
		};
	},

	methods: {
		getLayout() {
			if (this.$route.meta && this.$route.meta.layout) {
				switch (this.$route.meta.layout) {
					case 'blank':
						return layoutBlank.value;
					default:
						return layoutDefault.value;
				}
			} else {
				return layoutDefault.value;
			}
		},
	},
};
</script>
