/**
 * 布林值常數
 */

const items = [
	{
		id: true,
		name: 'Yes',
	},
	{
		id: false,
		name: 'No',
	},
];

export default items;
