/**
 * pinia for 文章留言按讚的api
 */


import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('articleCommentLike', {
	state: () => ({}),
	actions: {
		async update(articleID, articleCommentID, isLike) {
			try {
				const response = await post('/article_comment_like/update', {
					article_id: articleID,
					article_comment_id: articleCommentID,
					is_like: isLike,
				});
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
