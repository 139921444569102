/**
 * vue的i18n設定檔 目前未使用
 */

import { createI18n } from 'vue-i18n';

import en from './language/en-US.json';

const i18n = createI18n({
	legacy: false,
	locale: localStorage.getItem('locale') ?? 'zh-TW',
	fallbackLocale: 'zh-TW',

	// disable warning
	fallbackWarn: false,
	missingWarn: false,

	messages: {
		'en-US': en,
	},
});

export default i18n;
