/**
 * pinia for 使用者自己的文章的api
 */

import { defineStore } from 'pinia';

import { post } from '../helpers/main.js';

export default defineStore('selfArticle', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 10,
				_page: 1,
				_orderField: 'id',
				_orderDirection: 'asc',
				name: '',
			};

			condition = { ...baseCondition, ...condition };
			try {
				const response = await post('/self/article/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async getItem(q) {
			try {
				const response = await post('/self/article/get_item', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},

		async delete(q) {
			try {
				const response = await post('/self/article/delete', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},

		async batchUpdate(ids, typeID) {
			try {
				const response = await post('/self/article/batch_update', { ids: ids, type_id: typeID });
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
