/**
 * 方案類型
 */

const items = [
	{
		id: 1,
		name: '文章',
	},
	 
];

export default items;
