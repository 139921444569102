/**
 * 方案購買方式
 */

const items = [
	{
		id: 1,
		name: '持續訂閱',
	},
	{
		id: 2,
		name: '單次購買',
	},
];

export default items;
